'use strict'

const Window = class {
  constructor() {
    const windowLink = document.querySelector('.window_link'),
          windowWrap = document.querySelector('.window_wrap'),
          kvLink = document.getElementById('kv-link');
    
    const links = [windowLink, kvLink];
    links.forEach(l => {
      if (l) {
        l.addEventListener('mouseenter', (e) => {
          windowWrap.classList.add('mouseenter');
        });
        l.addEventListener('mouseleave', (e) => {
          windowWrap.classList.remove('mouseenter');
        });
      }
    });
  }
};
export default Window;
