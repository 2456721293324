import './polyfill'
import _ from 'lodash';
import state from './util/state';

import Bg from './modules/Bg';
import CommandC from './modules/CommandC';
// import Cursor from './modules/Cursor';
import Header from './modules/Header';
import Pjax from './modules/Pjax';
import Scroll from './modules/Scroll';
import ModalStack from './modules/ModalStack';

state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

window.addEventListener('DOMContentLoaded', function() {
  new Bg();
  new Header();
  new Pjax();
  new Scroll();

  if(state.dev === 'pc') {
    new CommandC();
  }

  // document.getElementById('bgCanvas').classList.add('ready');
  document.querySelector('.contents').classList.add('ready');
});

window.onload = function(){
  // Load functions
  if(state.dev === 'pc') {
    // new Scroll();
  }
};

window.addEventListener('resize', _.throttle(function() {
  state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

  if(state.dev === 'pc') {
    // new Cursor();
    // new Scroll();
  }
}, 500));
