'use strict'

import _ from 'lodash';
import state from '../util/state';

const Header = class {
  constructor() {
    document.ontouchmove = (e) => true;
    this.init();

    window.addEventListener('resize', _.throttle(() => {
      document.getElementById('js-tgl-nav').style.height = window.innerHeight + 'px';
    }, 1000));
  }

  init() {
    const headerNav = document.getElementById('js-header-nav'),
          headerLinks = document.querySelectorAll('#js-tgl-nav a');

    headerNav.addEventListener('click', (e) => {
      const btn = document.getElementById('js-header-nav'),
            nav = document.getElementById('js-tgl-nav'),
            obj = [btn,nav];

      if(!state.fixed) {
        document.ontouchmove = (e) => e.preventDefault();
        state.fixed = true;

        document.querySelector('body').classList.add('fixed');
        nav.style.height = window.innerHeight + 'px';

        for(let i = 0; i < obj.length; i++) {
          obj[i].setAttribute('data-current', 1);
        }

      } else {
        document.ontouchmove = () => true;
        state.fixed = false;

        document.querySelector('body').classList.remove('fixed');

        for(let i = 0; i < obj.length; i++) {
          obj[i].setAttribute('data-current', 0);
        }
      }
    });

    for (var i = 0; i < headerLinks.length; i++) {
      headerLinks[i].addEventListener('click', () => {

        document.querySelector('body').classList.remove('fixed');
        document.getElementById('js-header-nav').setAttribute('data-current', 0);
        document.getElementById('js-tgl-nav').setAttribute('data-current', 0);

        document.ontouchmove = () => true;
        state.fixed = false;
      });
    }
  }
};
export default Header;
