import Modal from './Modal';
import state from '../util/state';

export default class ModalStack {
  static ready() {
    const popups = document.querySelectorAll('.interview-popup');
    this.popups = Array.from(popups).reduce((p, $) => {
      const m = new Modal($, () => {
        this.close();
      });

      p[$.getAttribute('data-interview')] = m;
      return p;
    }, {})
  }

  static open(key) {
    const open = () => {
      this.modal = this.popups[key];
      this.href = location.href;
      this.modal.open();
      let $title = this.modal.$.querySelector('.popup_title');

      if ($title) {
        const $hTitle = document.getElementsByTagName('title')[0];
        this.popTitle = $hTitle.textContent;
        $hTitle.textContent = $title.innerHTML;
      }

      window.history.pushState({}, '', `/interviews/${key}`);
    }

    if (state.fixed) {
      this.close();
      setTimeout(open, 0);
    } else {
      open();
    }
  }

  static close() {
    console.log('close');
    this.modal.close();
    window.history.back();
    if (this.popTitle) {
      document.getElementsByTagName('title')[0].textContent = this.popTitle;
      this.popTitle = null;
    }
  }
}