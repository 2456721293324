'use strict'

import gsap from 'gsap';
import state from '../util/state.js';

const Splash = class {
  constructor() {

    const splash = document.querySelector('.splash');
    if(state.dev === 'sp') {
      splash.style.height = window.innerHeight + 'px';
    }

    if(document.querySelector('.message-splash_bg')) {
      const splashBg = document.querySelector('.message-splash_bg');
      // splashBg.style.height = window.innerHeight + 'px';
      gsap.to(splashBg, {
        opacity: 1,
        duration: .8
      });
    }

    this.tgt = {
      scrollTxt: document.querySelectorAll('.scroll-txt')
    };

    if(document.querySelector('.message-section')) {
      // Scroll Text
      window.addEventListener('scroll', _.throttle(this.scrlText.bind(this), 500));
    }

    this.init();
  }
  init() {
    const spDelay = (state.dev === 'pc') ? 0 : 2000;
    const duration = 0.6;

    const splashTxt01 = document.querySelector('.message-splash_txt01'),
          splashTxt02 = document.querySelector('.message-splash_txt02'),
          splashTxt02s = document.querySelector('.message-splash_txt02-static'),
          splashTxt03 = document.querySelector('.message-splash_txt03'),
          messageTitle = document.querySelector('.message-section_title'),
          // messageWrap = document.querySelector('.message-section'),
          splashBody = document.querySelector('.splash-body'),
          splashBg = document.querySelector('.message-splash_bg');

    // Setting padding top
    if(state.dev === 'sp') {
      if(messageTitle) {
        var pt = (window.innerHeight / 2) - 70;
        messageTitle.style.paddingTop = pt + 'px';
      }
    }


    setTimeout(() => {

      // 02. Show EN COPY
      gsap.to(splashTxt02, {
        opacity: 1,
        y: '-50%',
        duration: duration,
        delay: 1
      });
      gsap.to(splashTxt02, {
        opacity: 0,
        y: '-55%',
        duration: duration,
        delay: 2.5
      });
      gsap.to(splashTxt02s, {
        opacity: 0,
        // y: '-52%',
        duration: duration,
        delay: 2.5
      });

      // 03. JP COPY
      gsap.to(splashTxt03, {
        opacity: 1,
        y: '-50%',
        duration: duration,
        delay: 2.5
      });
      gsap.to(splashTxt03, {
        opacity: 0,
        y: '-55%',
        duration: duration,
        delay: 4
      });

      // 04. TITLE
      if(document.querySelector('.message-splash_txt03')) {
        gsap.to(messageTitle, {
          opacity: 1,
          // y: -5,
          duration: duration,
          delay: 4
        });
      } else {
        if (state.dev == 'sp') {
          gsap.to(messageTitle, {
            opacity: 1,
            // y: -5,
            duration: duration,
            delay: 1
          });
        } else {
          if (messageTitle) messageTitle.style.opacity = 1;
        }
      }

      // 05. BODY
      if(document.querySelector('.message-splash_only')) {
        gsap.to(splashBody, {
          opacity: 1,
          // y: -5,
          duration: duration,
          delay: 0
        });
      } else if(document.querySelector('.message-splash_txt03')) {
        gsap.to(splashBody, {
          opacity: 1,
          // y: -5,
          duration: duration,
          delay: 5.5,
        });
      } else {
        gsap.to(splashBody, {
          opacity: 1,
          // y: -5,
          duration: duration,
          delay: 1
        });
      }


    }, spDelay);

    // 01. SP only splash title
    if(state.dev === 'sp') {
      gsap.to(splashTxt01, {
        opacity: 1,
        y: '-50%',
        duration: duration,
        delay: 0.8
      });
      gsap.to(splashTxt01, {
        opacity: 0,
        y: '-55%',
        duration: duration,
        delay: 1.5
      });
    }



    // if(document.querySelector('.message-splash_txt03')) {
      // gsap.to(splashTxt03, {
      //   opacity: 1,
      //   y: '-50%',
      //   duration: duration,
      //   delay: delay03
      // });
      // gsap.to(splashTxt03, {
      //   opacity: 0,
      //   y: '-55%',
      //   duration: duration,
      //   delay: delay04
      // });
    //   gsap.to(messageTitle, {
    //     opacity: 1,
    //     // y: -5,
    //     duration: duration,
    //     delay: delay04
    //   });
    // } else {
    //   gsap.to(messageTitle, {
    //     opacity: 1,
    //     duration: duration,
    //     delay: delay03
    //   });
    // }

    // if(document.querySelector('.message-splash_only')) {
    //   gsap.to(splashBody, {
    //     opacity: 1,
    //     // y: -5,
    //     duration: duration,
    //     delay: delay01,
    //   });
    // } else if(document.querySelector('.message-splash_txt03')) {
    //   gsap.to(splashBody, {
    //     opacity: 1,
    //     // y: -5,
    //     duration: duration,
    //     delay: delay05,
    //   });
    //   gsap.to(messageWrap, {
    //     opacity: 1,
    //     y: -5,
    //     duration: duration,
    //     delay: delay05,
    //   });
    // } else {
    //   gsap.to(splashBody, {
    //     opacity: 1,
    //     // y: -5,
    //     duration: duration,
    //     delay: delay04,
    //   });
    //   gsap.to(messageWrap, {
    //     opacity: 1,
    //     y: -5,
    //     duration: duration,
    //     delay: delay04,
    //   });
    // }

    // if(document.querySelector('.message-splash_bg')) {
    //   gsap.to(splashBg, {
    //     opacity: 0,
    //     y: -5,
    //     duration: 1.5,
    //     delay: delay04
    //   });
    // }
  }
  scrlText() {
    if (50 < window.pageYOffset) {
      // .scroll-txt
      for (var i = 0; i < this.tgt.scrollTxt.length; i++) {
        this.tgt.scrollTxt[i].classList.add('inview');
      }
    }
  }
};
export default Splash;
