'use strict'

const Accordion = class {
  constructor() {
    const acdHeads = document.querySelectorAll('.js-acd-head');

    for (var i = 0; i < acdHeads.length; i++) {

      acdHeads[i].addEventListener('click', (e) => {
        e = e || window.event;
        const target = e.target || e.srcElement,
              acdBody = target.nextElementSibling;
        if (acdBody.style.height) {
          target.classList.remove('current');
          target.querySelector('.tgl-btn').classList.remove('current');
          acdBody.style.height = null;
        } else {
          target.classList.add('current');
          target.querySelector('.tgl-btn').classList.add('current');
          acdBody.style.height = acdBody.scrollHeight + 'px';
        }
      });
    }
  }
};
export default Accordion;
