'use strict'

import inView from 'in-view';
import state from '../util/state';

const Inview = class {
  constructor() {
    inView.threshold(0.5)
    inView('main .js-in').on(
      'enter',
      $ => $.classList.add('inview')
    )
    
    window.scroll({ top: 1 });
  }
}

export default Inview;
