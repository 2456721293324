'use strict'

import _ from 'lodash';
import barba from '@barba/core';
import gsap from 'gsap';
import state from '../util/state.js';

import $ from 'jquery';

import Accordion from './Accordion';
// import Cursor from './Cursor';
import Modal from './Modal';
import More from './More';
import Inview from './Inview';
import Splash from './Splash';
import Top from './Top';
import Who from './Who';
import Window from './Window';
import Portfolio from './Portfolio';
import ModalStack from './ModalStack.js';


const Pjax = class {
  constructor() {
    this.init();
    this.pjax();

    if(location.pathname === '/' || location.pathname === '/index.html') {
      return
    } else {
      document.querySelector('#bgCanvas').classList.add('ready');
    }

  }

  pjax() {
    barba.init({
      prevent: ({ el }) => el.classList && el.classList.contains('prevent'),
      transitions: [{
        sync: false,
        name: 'custom-transition',
        before({ current, next, trigger }) {
          if(trigger.classList.contains('js-window-belt-who')) {
            document.getElementById('bgCanvas').style.display = 'none';
          }
        },
        afterEnter({ current, next, trigger }) {
          document.querySelector('#page-logo').classList.remove('top-hide');

          // if(state.dev === 'sp') {
          //   document.querySelector('#page-logo').classList.add('hide');
          // }
          // console.log(next.url.hash);
          // var scrollNext = 0;
          //
          // if(next.url.hash) {
          //
          //   var target = $('#' + next.url.hash),
          //       position = target.offset().top;
          //   position = (position > 0) ? position - 0 : position;
          //   $('body,html').animate({scrollTop: position}, 800);
          // }
          // console.log(scrollNext);

          gsap.from(next.container, {
            // 'webkitFilter': 'blur(3px)',
            opacity: 0,
            // y: 10,
            duration: 0.7,
            delay: 0.5,
            onComplete: () => {
              if(trigger.classList.contains('js-window_link')) {
                document.querySelector('.js-sky').classList.remove('active');
              }
              if(trigger.classList.contains('js-window-belt-who')) {
                document.getElementById('bgCanvas').style.display = 'block';
                // document.querySelector('.js-sky').classList.remove('active');
              }
            }
          });
          return
        }
      }],
      views: [,
        {
          namespace: 'page_what',
          beforeEnter(data) {
            console.log('page_what');
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        },
        {
          namespace: 'page_recruit',
          beforeEnter(data) {
            console.log('page_recruit');
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        },
        {
          namespace: 'page_portfolio',
          beforeEnter(data) {
            console.log('page_portfolio');
            document.querySelector('#page-logo').classList.remove('top-hide');
          },
          afterEnter() {
            new Portfolio();
          }
        },
        {
          namespace: 'page_news',
          beforeEnter(data) {
            console.log('page_news');
            document.querySelector('#page-logo').classList.remove('top-hide');
          },
          afterEnter(data) {
            if (document.querySelector('.js-news-category-select')) {
              const selector = document.querySelector('.js-news-category-select'),
                    newsItem = document.querySelectorAll('.news-list_item');
              selector.addEventListener('change', (e) => {
                const val = e.target.value;

                if(val === 'all') {
                  for (var i = 0; i < newsItem.length; i++) {
                    newsItem[i].setAttribute('data-display',1);
                  }
                } else {
                  for (var i = 0; i < newsItem.length; i++) {
                    if (val === newsItem[i].getAttribute('data-category')) {
                      newsItem[i].setAttribute('data-display',1);
                    } else {
                      newsItem[i].setAttribute('data-display',0);
                    }
                  }
                }
              });
            }
          }
        },
        {
          namespace: 'page_contact',
          beforeEnter(data) {
            console.log('page_contact');
            document.querySelector('#page-logo').classList.remove('top-hide');
          },
          afterEnter(data) {
            var contactPage = document.querySelector('.page_contact');

            if(!document.querySelector('.wpcf7-mail-sent-ok')) {
              gsap.to(contactPage, {
                opacity: 1,
                duration: 0.4
              });
            }
          },
        },
        {
          namespace: 'page_index',
          beforeEnter(data) {
            // console.log('page_portfolio');
          },
          afterEnter(data) {
            document.querySelector('.kv').style.height = window.innerHeight + 'px';
            new Top();

            setTimeout(() => {
              document.querySelector('.kv_window').classList.add('ready');
            }, 500);

            setTimeout(() => {
              document.querySelector('.window_link').classList.add('ready');
              document.querySelector('.kv_title').classList.add('ready');
              document.querySelector('.kv_copy').classList.add('ready');
            }, 2000);

            setTimeout(() => {
              document.querySelector('#bgCanvas').classList.add('ready');
            }, 3000);
          },
          after(data) {
            if(state.dev === 'sp') {
              document.querySelector('#page-logo').classList.add('top-hide');
            }
          }
        },
        {
          namespace: 'page_who',
          beforeEnter(data) {
            console.log('page_who');
            document.querySelector('#page-logo').classList.remove('top-hide');
          },
          afterEnter(data) {
            new Who();
          }
        },
        {
          namespace: 'page_anycarry',
          beforeEnter() {
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        },
        {
          namespace: 'page_spaceengine',
          beforeEnter() {
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        },
        {
          namespace: 'page_ligua',
          beforeEnter() {
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        },
        {
          namespace: 'page_mirairo',
          beforeEnter() {
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        },
        {
          namespace: 'page_mil',
          beforeEnter() {
            document.querySelector('#page-logo').classList.remove('top-hide');
          }
        }
      ]
    });

    barba.hooks.enter(({ current, next }) => {
      console.log('hooks.enter')
      document.querySelector('#page-logo').classList.remove('hide');

      if(document.querySelector('.js-top-logo-sp')) {
        document.querySelector('.js-top-logo-sp').classList.add('ready');
      }

      const h1 = document.getElementById('page-title');
      gsap.to(h1, { opacity: 0, duration: 0.8, onComplete: () => {
        h1.textContent = '';
        this.pageTitle(next.url.href);
      } });
    });

    barba.hooks.after(({ current, next }) => {
      console.log('hooks.after')
      const h1 = document.getElementById('page-title');
      gsap.to(h1, { opacity: 1, duration: 0.8, delay: 1 });

      this.def();

      // Send GA
      ga('set', 'page', window.location.pathname);
      ga('send', 'pageview');
    });
  }

  pageTitle(url) {
    const h1 = document.getElementById('page-title')
    h1.textContent = '';

    if(url.indexOf('who') > 0) {
      h1.textContent = 'Who We Are';
    } else if(url.indexOf('what') > 0) {
      h1.textContent = 'What We do';
    } else if(url.indexOf('portfolio') > 0) {
      h1.textContent = 'Portflio';
    } else if(url.indexOf('news') > 0) {
      h1.textContent = 'News';
    } else if(url.indexOf('recruit') > 0) {
      h1.textContent = 'Recruit';
    } else if(url.indexOf('contact') > 0) {
      h1.textContent = 'Contact Us';
    } else if(url.indexOf('privacy') > 0) {
      h1.textContent = 'Privacy Policy';
    }
  }

  init() {
    this.def();
    // console.log(location.href);
    this.pageTitle(location.href);
    // document.querySelector('#page-logo').classList.remove('top-hide');
    // console.log('top-hide');

    if(document.querySelector('.js-top-logo-sp')) {
      document.querySelector('.js-top-logo-sp').classList.add('ready');
    }
  }

  def() {
    if(document.querySelector('.js-in')) {
      new Inview('.js-in');
    }
    if(document.querySelector('.js-acd-head')) {
      new Accordion();
    }
    if(document.querySelector('.js-more-wrap')) {
      new More();
    }
    if(document.querySelector('.splash')) {
      new Splash();
    }
    if(document.querySelector('.window')) {
      new Window();
    }

    ModalStack.ready();

    Array.from(document.querySelectorAll('.open-interview-popup')).forEach($ => {      
      $.addEventListener('click', () => {
        ModalStack.open($.getAttribute('data-interview'));
      });
    });
  }

}

export default Pjax;
