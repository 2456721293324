'use strict'

const More = class {
  constructor() {
    const moreWrap = document.querySelector('.js-more-wrap'),
          moreBtn = document.querySelector('.js-more-btn'),
          lessBtn = document.querySelector('.js-less-btn');

    moreBtn.addEventListener('click', (e) => {
      moreWrap.classList.add('open');
      moreBtn.classList.add('hide');
      lessBtn.classList.remove('hide');
    });
    lessBtn.addEventListener('click', (e) => {
      moreWrap.classList.remove('open');
      moreBtn.classList.remove('hide');
      lessBtn.classList.add('hide');
    });
  }
};
export default More;
