'use strict'

import _ from 'lodash';
import state from '../util/state';
import {EventEmitter} from 'events';

export default class Modal extends EventEmitter {
  constructor($, onClose) {
    super();

    document.ontouchmove = (e) => true;

    this.$ = $ || document.getElementById('popup');
    this.$content = this.$.querySelector('.popup_content');
    this.onClose = onClose;
    this.init();

    window.addEventListener('resize', _.throttle(() => {
      if(this.$) {
        this.$.style.height = window.innerHeight + 'px';
      }
    }, 1000));
  }

  init() {
    const popup = this.$, body = document.querySelector('body');

    popup.querySelectorAll('a.popup_close, .popup_bg').forEach($ => {
      $.addEventListener('click', () => {
        this.onClose ? this.onClose() : this.close();
      });
    })
  }

  open() {
    const popup = this.$, body = document.querySelector('body');

    state.fixed = true;
    body.classList.add('fixed');
    popup.classList.add('open');

    if(document.querySelector('.js-in-02, .js-in')) {
      this.inviewColumn();
    }

    if(state.dev === 'pc') {

      // popup.style.top = window.pageYOffset + 'px';
    }

    if (this.$content) this.$content.scrollTop = 0;
  }

  close() {
    const popup = this.$, body = document.querySelector('body');

    state.fixed = false;
    popup.classList.remove('open');
    body.classList.remove('fixed');
  }

  inviewColumn() {
    const popup = this.$, body = document.querySelector('body');
    const tgt = popup.querySelectorAll('.js-in-02, .js-in');

    // const tgt = (state.dev === 'pc') ? $('.js-in-pc') : $('.js-in-sp');
    (document.querySelector('.column') || popup.querySelector('.popup_content')).addEventListener('scroll', _.throttle(function() {
      for (var i = 0; i < tgt.length; i++) {
        if (tgt[i].getBoundingClientRect().top < window.innerHeight) {
          // this.tgt.bg.classList.add('hide');
          tgt[i].classList.add('inview');
        }
      }
    }), 500);

    // init
    setTimeout(() => {
      for (var i = 0; i < tgt.length; i++) {
        if (tgt[i].getBoundingClientRect().top < window.innerHeight) {
          // this.tgt.bg.classList.add('hide');
          tgt[i].classList.add('inview');
        }
      }
    }, 300);
  }
}