'use strict'

import state from '../util/state.js';
import ModalStack from './ModalStack.js';

const Top = class {
  constructor() {

    this.tgt = {
      win: document.querySelector('.js-window_wrap'),
      winH: window.innerHeight * 0.6,
      spLogo: document.querySelector('.js-top-logo-sp')
    };
    window.addEventListener('resize', _.throttle(() => {
      this.tgt.winH = window.innerHeight * 0.6;
    }, 1000));

    window.addEventListener('scroll', _.throttle(this.scrl.bind(this), 500));
    // this.scrl();

    if(state.dev === 'sp') {
      
      setTimeout(() => {
        document.querySelector('#page-logo').classList.add('hide');
        document.querySelector('#page-logo').classList.add('top-hide');
      });

      window.addEventListener('scroll', _.throttle(this.logo.bind(this), 300));
      this.logo();
    }

    setTimeout(() => {
      document.getElementById('kv-link').classList.add('ready');
    }, 3000);
  }

  scrl() {
    if (this.tgt.winH < window.pageYOffset) {
      this.tgt.win.classList.add('close');
      this.tgt.win.classList.remove('ready');
    } else {
      this.tgt.win.classList.remove('close');
      this.tgt.win.classList.add('ready');
    }
  }
  logo() {
    // console.log(window.pageYOffset);
    // this.tgt.spLogo.style.top = 110 - window.pageYOffset + 'px';
    //
    // if(this.tgt.spLogo.getBoundingClientRect().top < 27) {
    //   this.tgt.spLogo.style.top =  '26px';
    // } else {
    //   this.tgt.spLogo.style.top = 110 - window.pageYOffset + 'px';
    // }

    if (80 > window.pageYOffset) {
      this.tgt.spLogo.classList.remove('thin');
    } else {
      this.tgt.spLogo.classList.add('thin');
    }
  }
};
export default Top;
