'use strict'

import state from '../util/state';

const CommandC = class {
  constructor() {

    window.addEventListener('keydown', e => {
      if ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) {
        if(!state.commandC) {
          state.commandC = true;
          document.querySelector('body').classList.add('commandC');
        } else {
          state.commandC = false;
          document.querySelector('body').classList.remove('commandC');
        }
      }
    });
  }
};
export default CommandC;
