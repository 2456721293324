'use strict'

import Modal from './Modal';

const Who = class {
  constructor() {
    this.tgt = {
      bg: document.querySelector('.message-splash_bg')
    };

    window.addEventListener('scroll', _.throttle(this.scrl.bind(this), 500));
    // this.scrl();

    const modal = new Modal(document.getElementById('popup'));

    const $open = document.querySelector('.popup_open');
    $open.addEventListener('click', () => {
      modal.open();
    })
  }
  scrl() {
    if (document.querySelector('#ourteam')) {
      if (document.querySelector('#ourteam').getBoundingClientRect().top < window.innerHeight) {
        this.tgt.bg.classList.add('hide');
      } else {
        this.tgt.bg.classList.remove('hide');
      }
    }
  }
};
export default Who;
