'use strict'

/* =================================================
pageState
==================================================== */

const state = {
	dev: 'pc',
	fixed: false,
	commandC: false
};

export default state;
