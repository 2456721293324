'use strict'

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import _ from 'lodash';
import state from '../util/state';
import { Vector3, Quaternion } from 'three';

const Bg = class {
  constructor() {
    var width = window.innerWidth;
    var height = window.innerHeight;

    // レンダラーを作成
    const renderer = new THREE.WebGLRenderer({
      canvas: document.getElementById('bgCanvas')
    });
    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    // シーンを作成
    var scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf8f8f8);

    const axisAngle = (x, y, z, a) => {
      const q = new Quaternion();
      q.setFromAxisAngle(new Vector3(x, y, z), a);
      return q;
    }

    const makeTorus = (radius, tube, rotation, depth) => {
      var geometry = new THREE.TorusGeometry(radius, tube, 30, 200);
      //var geometry = new THREE.ConeGeometry(1, 2, 32);
      var material = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF
      });

      const mesh = new THREE.Mesh( geometry, material );
      mesh.rotation.setFromQuaternion(rotation);
      mesh.scale.z = 0.3;
      const g = new THREE.Group();
      g.add(mesh);
      g.position.z = -depth;
      return g;
    }

    const torus1 = makeTorus(2, 0.4, axisAngle(0, -1, 0, Math.PI / 180 * 10), 0.2);
    torus1.position.x = -1.5
    const torus2 = makeTorus(6, 0.5, axisAngle(-1, 0, 0, Math.PI / 180 * 10), 0.75);
    torus2.position.y = -6
    const torus3 = makeTorus(6, 1, axisAngle(0, -1, 0, Math.PI / 180 * 15), 1.2);
    torus3.position.x = 5;
    torus3.position.y = -3;
    const torus4 = makeTorus(6, 0.25, axisAngle(0, 1, 0, Math.PI / 180 * 5), 0.4);
    torus4.position.x = -5;
    torus4.position.y = -4;

    scene.add(torus1);
    scene.add(torus2);
    scene.add(torus3);
    scene.add(torus4);

    scene.fog = new THREE.Fog(0xF8F8F8, 2.2, 2.5);

    // カメラを作成
    const camera = new THREE.PerspectiveCamera(
      45,
      width / height,
      0.1,
      2.5
    );

    camera.position.set(0, 0, 2.5);
    camera.lookAt(0, 0, 0);

    const lighten = () => {
      const spotLight = new THREE.SpotLight( 0x222222);
      spotLight.position.set(0, 0, 25);
      scene.add(spotLight);
    }

    const ambientLight = new THREE.AmbientLight(0xF7F7F7, 0.9);
    scene.add(ambientLight);

    lighten();

    // フォグを設定
    // new THREE.Fog(色, 開始距離, 終点距離);
    //scene.fog = new THREE.Fog(0x000000, 10, 200);

    const end1 = 300;
    const end2 = end1;
    const end3 = end1 * 2.5;
    const end4 = end1 * 2;
    const camend = end1 * 12;

    const animate = (o, clockwise) => t => {
      o.rotation.z = (clockwise ? -1 : 1) * Math.PI * 2 * t;
    }

    const step1 = animate(torus1, true);
    const step2 = animate(torus2, true);
    const step3 = animate(torus3, false);
    const step4 = animate(torus4, false);

    const step = age => {
      const t1 = (((age-1) % end1) + 1) / end1;
      const t2 = (((age-1) % end2) + 1) / end2;
      const t3 = (((age-1) % end3) + 1) / end3;
      const t4 = (((age-1) % end4) + 1) / end4;
      const tc = (((age-1) % camend) + 1) / camend;

      step1(t1);
      step2(t2);
      step3(t3);
      step4(t4);

      camera.rotation.z = tc * Math.PI * 2;

      renderer.render(scene, camera);
    }

    let age = 0;
    let shouldResize = false;

    function tick() {
      age++;
      if (age % 2 == 1) {
        if (shouldResize) {
          const width = window.innerWidth;
          const height = window.innerHeight;

          // レンダラーのサイズを調整する
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(width, height);

          // カメラのアスペクト比を正す
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
          
          shouldResize = false;
        }
        step(age / 2);
      }
      requestAnimationFrame(tick);
    }

    // 初回実行
    tick();

    // Resize
    window.addEventListener('resize', _.throttle(function() {
      shouldResize = true;
    }, 500));
  }
};
export default Bg;
