'use strict'

import _ from 'lodash';

const Scroll = class {
  constructor() {
    this.tgt = {
			logo: document.querySelector('#page-logo'),
      title: document.querySelector('#page-title')
    }
    window.addEventListener('scroll', _.throttle(this.scrl.bind(this), 500));
    this.scrl();
  }
  scrl() {
    // const pos = document.querySelector('footer.global').getBoundingClientRect().top + this.tgt.spc;
    const pos = document.querySelector('footer.global').getBoundingClientRect().top;

    if (200 > window.pageYOffset) {
      this.tgt.logo.classList.remove('thin');
      this.tgt.title.classList.remove('hide');
    } else {
      this.tgt.logo.classList.add('thin');
      this.tgt.title.classList.add('hide');
    }

    const logoPos = window.innerHeight - 150;
    // console.log(logoPos);
    // console.log(pos);
    if (logoPos >= pos) {
      this.tgt.logo.classList.add('hide');
    } else {
      this.tgt.logo.classList.remove('hide');
    }
  }
};
export default Scroll;
