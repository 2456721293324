import state from '../util/state.js';

export default class {
  constructor() {
    if(state.dev === 'sp') {
      document.querySelectorAll('.thumb')
        .forEach($ => {
          let hover = false;
          $.addEventListener('click', () => $.classList[(hover=!hover) ? 'add' : 'remove']('hover'));
        });
    }
  }
};